module.exports = {
  siteTitle: 'Alt-Med Businessplan', // <title>
  manifestName: 'Alt Med BP',
  manifestShortName: 'Alt-Med', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/alt-med/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Alt-Med',
  heading: 'Business Plan',
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/altmed11',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/Alt-Med-108235510918242',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:ray@blocklab.ch',
    },
  ],
};
